import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "namecards"
    }}>{`namecards`}</h1>
    <p>{`Namecard components for ReactJS`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.npmjs.com/package/namecards"
      }}><img alt="NPM" src="https://img.shields.io/npm/v/namecards.svg" /></a></p>
    <h2 {...{
      "id": "install"
    }}>{`Install`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`npm install --save namecards
`}</code></pre>
    <h2 {...{
      "id": "license"
    }}>{`License`}</h2>
    <p>{`(MIT OR Apache-2.0) © `}<a parentName="p" {...{
        "href": "https://github.com/disjukr"
      }}>{`JongChan Choi`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      